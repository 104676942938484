define("discourse/plugins/discourse-adplugin/discourse/templates/admin/plugins-house-ads-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="house-ads-settings content-body">
    <div>{{i18n "admin.adplugin.house_ads.description"}}</div>
  
    {{#if this.houseAds.length}}
      <form class="form-horizontal">
        {{house-ads-list-setting
          name="topic_list_top"
          value=this.adSettings.topic_list_top
          allAds=this.houseAds
          adSettings=this.adSettings
        }}
        {{house-ads-list-setting
          name="topic_above_post_stream"
          value=this.adSettings.topic_above_post_stream
          allAds=this.houseAds
          adSettings=this.adSettings
        }}
        {{house-ads-list-setting
          name="topic_above_suggested"
          value=this.adSettings.topic_above_suggested
          allAds=this.houseAds
          adSettings=this.adSettings
        }}
        {{house-ads-list-setting
          name="post_bottom"
          value=this.adSettings.post_bottom
          allAds=this.houseAds
          adSettings=this.adSettings
        }}
        {{house-ads-list-setting
          name="topic_list_between"
          value=this.adSettings.topic_list_between
          allAds=this.houseAds
          adSettings=this.adSettings
        }}
  
        <DButton
          @label="admin.adplugin.house_ads.more_settings"
          @icon="cog"
          @action={{route-action "moreSettings"}}
          class="btn-default"
        />
      </form>
    {{else}}
      <p>
        {{#link-to route="adminPlugins.houseAds.show" model="new"}}
          {{i18n "admin.adplugin.house_ads.get_started"}}
        {{/link-to}}
      </p>
    {{/if}}
  </section>
  */
  {
    "id": "yhz8zBY6",
    "block": "[[[10,\"section\"],[14,0,\"house-ads-settings content-body\"],[12],[1,\"\\n  \"],[10,0],[12],[1,[28,[35,2],[\"admin.adplugin.house_ads.description\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"houseAds\",\"length\"]],[[[1,\"    \"],[10,\"form\"],[14,0,\"form-horizontal\"],[12],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"topic_list_top\",[30,0,[\"adSettings\",\"topic_list_top\"]],[30,0,[\"houseAds\"]],[30,0,[\"adSettings\"]]]]]],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"topic_above_post_stream\",[30,0,[\"adSettings\",\"topic_above_post_stream\"]],[30,0,[\"houseAds\"]],[30,0,[\"adSettings\"]]]]]],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"topic_above_suggested\",[30,0,[\"adSettings\",\"topic_above_suggested\"]],[30,0,[\"houseAds\"]],[30,0,[\"adSettings\"]]]]]],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"post_bottom\",[30,0,[\"adSettings\",\"post_bottom\"]],[30,0,[\"houseAds\"]],[30,0,[\"adSettings\"]]]]]],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"topic_list_between\",[30,0,[\"adSettings\",\"topic_list_between\"]],[30,0,[\"houseAds\"]],[30,0,[\"adSettings\"]]]]]],[1,\"\\n\\n      \"],[8,[39,6],[[24,0,\"btn-default\"]],[[\"@label\",\"@icon\",\"@action\"],[\"admin.adplugin.house_ads.more_settings\",\"cog\",[28,[37,7],[\"moreSettings\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,2],[12],[1,\"\\n\"],[6,[39,9],null,[[\"route\",\"model\"],[\"adminPlugins.houseAds.show\",\"new\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,2],[\"admin.adplugin.house_ads.get_started\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13]],[],false,[\"section\",\"div\",\"i18n\",\"if\",\"form\",\"house-ads-list-setting\",\"d-button\",\"route-action\",\"p\",\"link-to\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/templates/admin/plugins-house-ads-index.hbs",
    "isStrictMode": false
  });
});